"use client";

import classNames from "classnames";
import {
  ButtonHTMLAttributes,
  forwardRef,
  memo,
  MouseEvent,
  ReactNode,
} from "react";
import styled, { css } from "styled-components";

import { spacing } from "libs/styles/variables";

type ButtonTheme =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "white"
  | "black12";
type ButtonSize = "sm" | "md" | "lg";
type ButtonType = "button" | "submit";

type ButtonProps = {
  buttonTheme?: ButtonTheme;
  size?: ButtonSize;
  block?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  children: ReactNode;
};

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonProps> &
  ButtonProps;

const buttonThemeClasses: Record<ButtonTheme, string> = {
  default:
    "bg-primary-base-light border-primary-base-light text-black-87 hover:border-black-04 focus:border-primary-dark-1 active:border-black-12",
  primary:
    "bg-primary border-primary text-white-100 hover:bg-primary-dark-1 hover:border-primary-dark-1 focus:border-primary-dark-1 active:bg-primary-dark-2 active:border-primary-dark-2",
  secondary:
    "bg-secondary border-secondary text-white-100 hover:bg-secondary-dark-1 hover:border-secondary-dark-1 focus:border-secondary-dark-2 active:bg-secondary-dark-2 active:border-secondary-dark-2",
  danger:
    "bg-alert border-alert text-white-100 hover:bg-alert-dark-1 hover:border-alert-dark-1 focus:border-alert-dark-1 active:bg-alert-dark-2 active:border-alert-dark-2",
  white:
    "bg-white-100 border-white-100 text-black-87 hover:bg-white-87 hover:border-white-87 focus:border-primary-dark-1 active:bg-white-56 active:border-white-56",
  black12:
    "bg-transparent border-black-12 text-black-38 hover:bg-black-12 hover:border-black-12 focus:border-black-12 active:bg-black-12 active:border-black-12",
};

const sizeClasses: Record<ButtonSize, string> = {
  sm: "rounded-full text-xs h-7.5 py-0 px-md",
  md: "rounded-md text-sm h-12 py-0 px-xxl",
  lg: "rounded-md text-sm h-14 py-0 px-xxl",
};

export const Button = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        block,
        children,
        className,
        disabled,
        onClick,
        size = "md",
        buttonTheme = "default",
        type = "button",
        ...otherProps
      },
      ref,
    ) => {
      return (
        <Base
          $block={block}
          className={classNames(
            className,
            [
              "items-center",
              "border-2 border-solid",
              "box-border",
              disabled ? "cursor-default" : "cursor-pointer",
              "font-default",
              "font-medium",
              "justify-center",
              "outline-0",
              "relative",
              "text-center",
              "no-underline",
              "hover:shadow-2xs",
              "active:transition-none",
              block ? ["flex w-full"] : "inline-flex",
            ],
            disabled
              ? "bg-white-100 border-black-04 text-black-38"
              : buttonThemeClasses[buttonTheme],
            sizeClasses[size],
          )}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          $size={size}
          type={type}
          {...otherProps}
        >
          {children}
        </Base>
      );
    },
  ),
);

Button.displayName = "Button";

type BaseProps = {
  $size?: ButtonSize;
  $block?: boolean;
};

const Base = styled.button<BaseProps>`
  ${({ $size }) => getSizeStyles($size)}
`;

const getSizeStyles = (size?: ButtonSize) => {
  switch (size) {
    case "sm":
      return css`
        > svg {
          height: 18px;
          margin-left: -${spacing.xxs};
          margin-right: ${spacing.xs};
          width: 18px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.xs};
          margin-right: -${spacing.xxs};
        }
      `;
    case "md":
      return css`
        > svg {
          height: 20px;
          margin-left: -${spacing.md};
          margin-right: ${spacing.sm};
          width: 20px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.md};
        }
      `;
    case "lg":
      return css`
        > svg {
          height: 24px;
          margin-left: -${spacing.sm};
          margin-right: ${spacing.sm};
          width: 24px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.sm};
        }
      `;
  }
};
