"use client";

import classNames from "classnames";
import { Colors } from "components/colors";
import { Font } from "components/font";
import { radius, spacing } from "libs/styles/variables";
import { InputHTMLAttributes, forwardRef, memo } from "react";
import { FieldError } from "react-hook-form";
import styled, { css } from "styled-components";

type Props = {
  type?:
    | "text"
    | "password"
    | "email"
    | "number"
    | "search"
    | "tel"
    | "datetime-local"
    | "date";
  block?: boolean;
  rows?: number;
  error?: FieldError;
} & Omit<InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, never>;

export const TextField = memo(
  forwardRef<HTMLInputElement, Props>(
    ({ block, type = "text", className, error, ...otherProps }, ref) => {
      return (
        <BaseInput
          ref={ref}
          type={type}
          $block={!!block}
          $error={!!error}
          aria-invalid={!!error}
          {...otherProps}
          className={classNames(className)}
        />
      );
    },
  ),
);

const BaseInput = styled.input<{ $block: boolean; $error: boolean }>`
  background: ${Colors.primaryBaseLight};
  border: 2px solid transparent;
  border-radius: ${radius.md};
  box-sizing: border-box;
  font-family: ${Font.fontFamilyDefault};
  font-size: ${Font.fontSizeMd};
  font-weight: 400;
  height: 56px;
  outline: 0;
  padding: 0 ${spacing.md};

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 999em ${Colors.primaryBaseLight} inset;
  }
  &:hover {
    border-color: ${Colors.black04};
  }
  &:focus {
    border-color: ${Colors.primaryDark1};
  }

  ${({ $block }) => $block && DisplayBlock}
  ${({ $error }) => $error && DisplayError}
`;

const DisplayBlock = css`
  display: block;
  width: 100%;
`;

const DisplayError = css`
  background: ${Colors.alertLight2};
  -webkit-box-shadow: 0 0 0 999em ${Colors.alertLight2} inset;
  box-shadow: 0 0 0 999em ${Colors.alertLight2} inset;
`;
