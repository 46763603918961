import { FieldError, Merge } from "react-hook-form";
import {
  checkPasswordContainsAlphabet,
  checkPasswordContainsNumber,
  checkPasswordLength,
} from "./password_validator";

export function getErrorMessage(
  errors: Merge<FieldError, (FieldError | undefined)[]> | null | undefined,
) {
  const error = (Array.isArray(errors) ? errors : [errors]).filter(Boolean)[0];
  if (!error) return null;
  switch (error.type) {
    case "required":
      return error.message || "入力してください";
  }

  return error.message;
}

export const validatePassword = (pass: string): any => {
  const result: boolean =
    checkPasswordLength(pass) &&
    checkPasswordContainsAlphabet(pass) &&
    checkPasswordContainsNumber(pass);

  if (!result) {
    return "条件を満たしてください";
  }

  return result;
};
